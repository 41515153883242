<template>
	<div>
		<div class="fix">
			<div class="side-info">
				<button class="side-info-close"><i class="fa fa-times"></i></button>
				<div class="side-info-content">
					<div class="mobile-menu"></div>
				</div>
			</div>
		</div>
		<div class="offcanvas-overlay"></div>

		<!-- page title start  -->
		<section class="page__title  fix text-center">
			<div class="slider__shape">
				<img class="shape triangle" src="/static/picture/topelips.png" alt="topshape">
				<img class="shape dotted-square" src="/static/picture/rightelips.png" alt="rightshape">
				<img class="shape solid-square" src="/static/picture/circleelips.png" alt="solid-square">
			</div>
			<div class="container">
				<div class="row">
					<div class="col-xl-12">
						<div class="page__title-content pt-170">
							<h2 class="page_title">微行，给旅行更多可能</h2>
							<p style="color: white">我们的愿景：成为最受信赖的旅游与生活服务平台</p>
							<nav aria-label="breadcrumb">
								<div class="cta__freeBtn">
									<router-link class="cta_btn_2" to="/">立即体验</router-link>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- page title end  -->


		<!-- === 旅游产品 AREA START  === -->
		<div class="experience_area wow fadeInUp">
			<div class="section_title_wrapper text-center mb-70 mt-50">
				<h2 class="section-title">旅游产品</h2>
				<p style="text-align: center">微行从旅游产品资源到供应商都给予高于行业标准的接待要求，将旅游与服务重新定义，让旅游回归本质。<br/>与全球合作伙伴联手打造最具目的地特色的经典线路，多元化产品，多层次服务，多维度体验。</p>
			</div>
			<div class="container">
				<div class="row ">
					<template v-for="item in travel_product_features">
						<div class="col-xl-4 col-lg-4 col-md-4">
							<div class="service service_page">
								<div class="service__icon servicepageIcon sbg1">
									<img :src="item.icon" alt="service_image"/>
								</div>
								<div class="service__content servicecontent">
									<h4>{{item.title}}</h4>
									<p>{{item.desc}}</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!-- === 旅游产品 AREA END  === -->


		<!-- === 机票产品 AREA START  === -->
		<div class="experience_area wow fadeInUp" style="background-color: #459eff1a">
			<div class="section_title_wrapper text-center mb-70 mt-50">
				<h2 class="section-title">机票产品</h2>
				<p style="text-align: center">超100家全球深度合作航空公司，航线覆盖全球各国城市，各航线的特价机票一<br/>应俱全，一手资源让您享受最优惠的机票价格，支持线上查询，秒出票</p>
			</div>
			<div class="container">
				<div class="row">
					<template v-for="item in ticket1_product_features">
						<div class="col-xl-4 col-lg-4 col-md-4">
							<div class="service service_page">
								<div class="service__icon servicepageIcon sbg1">
									<img :src="item.icon" alt="service_image"/>
								</div>
								<div class="service__content servicecontent">
									<h4>{{item.title}}</h4>
									<p>{{item.desc}}</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!-- === 机票产品 AREA END  === -->


		<!-- === 酒店产品 AREA START  === -->
		<div class="experience_area wow fadeInUp">
			<div class="section_title_wrapper text-center mb-70 mt-50">
				<h2 class="section-title">酒店产品</h2>
				<p style="text-align: center">全球各大知名酒店连锁集团官方合作，打造每个旅游目的地城市不同<br/>主题，不同体验的区域酒店到特色民宿，应有尽有，微行三大保障：<br/>价格优、24小时旅游顾问服务、入住保障。</p>
			</div>
			<div class="container">
				<div class="row" style="flex-direction: column; align-content: center;">
					<template v-for="item in hotel_product_features">
						<div class="col-xl-6 col-lg-6 col-md-6">
							<div style="display: flex; flex-direction: row; align-items: center;">
								<div class="service__icon sbg2" style="flex: 0 0 120px; height: 120px; line-height: 120px;">
										<img :src="item.icon" style="margin-left: 40px;" alt="service_image"/>
								</div>
								<div style="margin-left: 32px;">
									<h4>{{item.title}}</h4>
									<p>{{item.desc}}</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!-- === 酒店产品 AREA END  === -->


		<!-- === 门票及活动产品 AREA START  === -->
		<div class="experience_area wow fadeInUp" style="background-color: #459eff1a">
			<div class="section_title_wrapper text-center mb-70 mt-50">
				<h2 class="section-title">门票及活动产品</h2>
				<p style="text-align: center">微行与全球景点携手合作，全网覆盖国内、国际门票，囊括了交通车、索道、<br/>缆车、游船、景点、文化赛事、特色演出、文化活动等直线合作折扣多，<br/>快速购票、出票。为微客提供更多优惠及选择。</p>
			</div>
			<div class="container">
				<div class="row">
					<template v-for="item in ticket2_product_features">
						<div class="col-xl-6 col-lg-6 col-md-6">
							<div class="service service_page">
								<div class="service__icon servicepageIcon sbg1">
									<img :src="item.icon" alt="service_image"/>
								</div>
								<div class="service__content servicecontent">
									<h4>{{item.title}}</h4>
									<p>{{item.desc}}</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!-- === 门票及活动产品 AREA END  === -->


		<!-- === 目的地特色商品 AREA START  === -->
		<div class="experience_area wow fadeInUp">
			<div class="section_title_wrapper text-center mb-70 mt-50">
				<h2 class="section-title">目的地特色商品</h2>
				<p style="text-align: center">众多旅游目的地隐藏着很多质量好、性价比高的优质特产，将其精选打包，让微客们享受<br/>旅行的同时也能收获货真价实的美好。除了各地特产，更有全球国际大牌的免税商品为微<br/>客们省钱，省时，省力。</p>
			</div>
			<div class="container">
				<div class="row">
					<template v-for="item in shop_product_features">
						<div class="col-xl-4 col-lg-4 col-md-4">
							<div class="service service_page">
								<div class="service__icon servicepageIcon sbg1">
									<img :src="item.icon" alt="service_image"/>
								</div>
								<div class="service__content servicecontent">
									<h4>{{item.title}}</h4>
									<p>{{item.desc}}</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!-- === 目的地特色商品 AREA END  === -->

		<!-- === HOME-2 CTA AREA START  === -->
		<div class="cta_area pt-100 pb-100 wow fadeInUp">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="cta">
							<h2 class="sub-title">如果您对我们的产品非常感兴趣，<br/>但还存在一点疑惑</h2>
							<div class="cta__freeBtn">
								<router-link class="cta_btn_2" to="/aboutMe">联系我们</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- === HOME-2 CTA AREA END  === -->

		<remote-script src="/static/js/main.js"></remote-script>

	</div>
</template>

<script>
	import '@/utils/importJs.js'

	export default {
		components: {},
		data() {
			return {
				travel_product_features: [
					{
						icon: "/static/picture/1.png",
						title: "跟团游",
						desc: "微行匠心出品目的地爆款旅游路线，省心省力，费用可控，100%保障您的游玩时间及游玩体验，沉浸式团体互动交流，拒绝雷同，告别走马观花。",
					},
					{
						icon: "/static/picture/2.png",
						title: "定制游",
						desc: "微行独家二对一私人定制服务，既不用跟团也不用费心做攻略，您的需求我们来满足，随心所欲就是这么简单。",
					},
					{
						icon: "/static/picture/3.png",
						title: "自由行",
						desc: "严选精品路线，极致性价比的机票+酒店套餐组合，比自行预定散客机票、酒店便宜20%起，让您的钱花在“刀刃”上。",
					}
				],

				ticket1_product_features: [
					{
						icon: "/static/picture/4.png",
						title: "国内机票",
						desc: "与国内各大航空公司签署长期合作协议，航班覆盖全国各大省市，为您提供更优惠的机票选择",
					},
					{
						icon: "/static/picture/5.png",
						title: "国际机票",
						desc: "与全球100多家国际航空公司签署长战略期合作协议，航线覆盖大广，为您出境提供更多选择。",
					},
					{
						icon: "/static/picture/6.png",
						title: "包机业务",
						desc: "资深的旅游目的地航线开发经验，与国内外各规模航空公司达成国际、国内直飞包机等航线运营合作协议，整合航空资源提供国内外大小型包机业务。",
					}
				],

				hotel_product_features: [
					{
						icon: "/static/picture/7.png",
						title: "国内酒店",
						desc: "与国内各大知名品牌连锁酒店签署长期合作协议，酒店品类及区域覆盖光。倡导美好生活品质，为微客提供优良出行住宿体验。",
					},
					{
						icon: "/static/picture/8.png",
						title: "国际酒店",
						desc: "与全球200多个国家和地区酒店签署长期合作协议，国际酒店品牌深度合作，搜罗目的地高端精品酒店度假村，全方位满足微客们商务度假需求。",
					},
				],

				ticket2_product_features: [
					{
						icon: "/static/picture/9.png",
						title: "周边门票",
						desc: "全网覆盖国内、国际门票一网打尽，为微客快速购票、快速出票。",
					},
					{
						icon: "/static/picture/10.png",
						title: "本地活动",
						desc: "微行携手全球目的地当地文化赛事/文化活动/特色演出等友好合作，丰富微客生活体验。",
					}
				],

				shop_product_features: [
					{
						icon: "/static/picture/11.png",
						title: "当地特产",
						desc: "微行专注于旅游产品和服务的同时，也深挖于全国各地于文化内涵或历史为意义的当地特产，为大家盘点了全国各地的十大特产，囊括了个直辖市、各大省、各大自治区以及港澳台地区等。在旅游、出差之际，让你再也不会为了思考买什么特产而伤透脑筋了。",
					},
					{
						icon: "/static/picture/12.png",
						title: "免税商品",
						desc: "微行为了解决微客们的免税需求，于全球各大免税店签署深度合作，折扣力度大，产品涉及有国际知名品牌的服装，美妆，香水，珠宝，烟酒，表类，食品等种类齐全，为微客们解决货真价优的便利。",
					},
					{
						icon: "/static/picture/13.png",
						title: "文创产品",
						desc: "结合当地文化与历史，搜罗当地最具文化赋能的日用纪念品，标志性的历史回忆文创产品。",
					}
				]

			}
		}

	}
</script>

<style scoped>

</style>